<template>
  <div class="enclosurebox" @click.stop>
    <img class="cha" @click="close" src="../assets/img/ding/cha.png" alt="" />
    <div class="cancelMain">
      <p class="cancelTit">{{ detail.efname }}</p>

      <div class="taskDetails" v-if="detail.status > 0">
        <div class="informationTit">异常处理<div class="dealyes">已处理</div></div>
        <div class="taskBox taskjianju1">
          <div class="tasktxt2">
            <label class="carTxt" for="">处理人员:&#12288;</label>
            <span class="taskBoxTit">{{ detail.user }}</span>
          </div>
          <div class="tasktxt1">
            <label class="carTxt" for="">处理时间:</label>
            <span class="carTit">{{ detail.vtime }}</span>
          </div>
          <div class="tasktit2">
            <label class="carTxt" for="">处理时长:</label>
            <span class="carTit">{{ detail.timelong }}</span>
          </div>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">处理原因:</label>
          <span class="taskRenwu">{{ detail.reply }}</span>
        </div>
      </div>
      <!-- 车主信息  技师信息 -->
      <div class="taskDetails">
        <div class="informationTit">技师信息</div>
        <div class="taskBox taskjianju1">
          <div class="tasktxt">
            <label class="carTxt" for="">司机：</label>
            <span class="taskBoxTit">{{ detail.name }}/{{ detail.tel }}  <img
                class="callImg"
                @click="call(detail.tel)"
                src="../assets/img/index/call.png"
                alt=""
                v-if="emicreg != ''"
              /></span>
          </div>
          <div class="tasktxt">
            <label class="carTxt" for="">车牌号:</label>
            <span class="taskRenwu">{{ detail.carNumber }}</span>
          </div>
        </div>
      </div>

      <!-- 报警信息 -->
      <div class="taskDetails">
        <div class="informationTit">报警信息</div>
        <div class="taskBox">
          <label class="carTxt" for="">报警时间:</label>
          <span class="taskBoxTit">{{ detail.warningTime }}</span>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">报警位置:</label>
          <span class="taskRenwu">{{ detail.warningAddr }}</span>
        </div>
      </div>

      <!-- 报警订单信息 -->
      <div
        class="taskDetails"
        v-if="detail.detail1 != '' && detail.detail1 != undefined"
      >
        <div class="informationTit">报警订单信息</div>
        <div class="taskBox">
          <label class="carTxt" for="">订单号:&#12288;</label>
          <span
            class="applynum"
            @click="enclosurepart(detail.detail1.rescueId)"
            >{{ detail.detail1.rescueId }}</span
          >
        </div>
        <div class="taskBox taskjianju1">
          <div class="tasktitmain">
            <label class="carTxt" for="">服务项目:</label>
            <span class="taskBoxTit">{{ detail.detail1.serviceType }}</span>
          </div>
          <div class="tasktitmain">
            <label class="carTxt" for="">服务状态:</label>
            <span class="taskBoxTit">{{ detail.detail1.status }}</span>
          </div>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">接单时间:</label>
          <span class="taskBoxTit">{{ detail.detail1.takeTime }}</span>
        </div>
        <div class="taskBox taskjianju1">
          <div class="tasktitmain">
            <label class="carTxt" for="">开始时间:</label>
            <span class="taskBoxTit">{{ detail.detail1.startTime }}</span>
          </div>
          <div class="tasktitmain">
            <label class="carTxt" for="">完成时间:</label>
            <span class="taskBoxTit">{{ detail.detail1.endTime }}</span>
          </div>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">合作单位:</label>
          <span class="taskRenwu">{{ detail.detail1.from }}</span>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">任务点:&#12288;</label>
          <span class="taskRenwu">{{ detail.detail1.addr }}</span>
        </div>
        <div class="taskBox" v-if="detail.detail1.dest != ''">
          <label class="carTxt" for="">目的地:&#12288;</label>
          <span class="taskRenwu">{{ detail.detail1.dest }}</span>
        </div>
      </div>

      <!-- 报警下一单信息 -->
      <div
        class="taskDetails"
        v-if="detail.detail2 != '' && detail.detail2 != undefined"
      >
        <div class="informationTit">报警下一单信息</div>
        <div class="taskBox">
          <label class="carTxt" for="">订单号:&#12288;</label>
          <span
            class="applynum"
            @click="enclosurepart(detail.detail2.rescueId)"
            >{{ detail.detail2.rescueId }}</span
          >
        </div>
        <div class="taskBox taskjianju1">
          <div class="tasktitmain">
            <label class="carTxt" for="">服务项目:</label>
            <span class="taskBoxTit">{{ detail.detail2.serviceType }}</span>
          </div>
          <div class="tasktitmain">
            <label class="carTxt" for="">服务状态:</label>
            <span class="taskBoxTit">{{ detail.detail2.status }}</span>
          </div>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">接单时间:</label>
          <span class="taskBoxTit">{{ detail.detail2.takeTime }}</span>
        </div>
        <div class="taskBox taskjianju1">
          <div class="tasktitmain">
            <label class="carTxt" for="">开始时间:</label>
            <span class="taskBoxTit">{{ detail.detail2.startTime }}</span>
          </div>
          <div class="tasktitmain">
            <label class="carTxt" for="">完成时间:</label>
            <span class="taskBoxTit">{{ detail.detail2.endTime }}</span>
          </div>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">合作单位:</label>
          <span class="taskRenwu">{{ detail.detail2.from }}</span>
        </div>
        <div class="taskBox">
          <label class="carTxt" for="">任务点:&#12288;</label>
          <span class="taskRenwu">{{ detail.detail2.addr }}</span>
        </div>
        <div class="taskBox" v-if="detail.detail2.dest != ''">
          <label class="carTxt" for="">目的地:&#12288;</label>
          <span class="taskRenwu">{{ detail.detail2.dest }}</span>
        </div>
      </div>

      <!-- 围栏地图 -->
      <div class="map-box">
        <div id="suremap"></div>
      </div>

      <!-- 报警缘由 -->
      <div class="taskDetails" v-if="detail.status == 0">
        <div class="informationTit">报警信息</div>
        <div class="taskBox2">
          <label class="carTxt" for="">报警缘由</label>
          <el-select class="input-unit" v-model="value" @change="policeChange">
            <el-option
              v-for="item in policelist"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>

        <div class="taskBox2" v-if="policetit">
          <label class="carTxt" for="">报警缘由</label>
          <el-input
            class="text"
            v-model="textarea"
            autosize
            type="textarea"
            placeholder="请输入报警缘由"
          />
        </div>

        <div class="taskBox2">
          <label class="carTxt">审核意见</label>
          <div class="yuradio">
            <el-radio v-model="isrescue" label="1" size="large">同意</el-radio>
            <el-radio v-model="isrescue" label="2" size="large"
              >不同意</el-radio
            >
          </div>
        </div>
      </div>

      <!-- 提交后 -->
      <div class="btn" v-if="detail.status == 0">
        <el-button class="set" :plain="true" @click="create">提交</el-button>
        <button class="cancel" @click="close">取消</button>
      </div>
    </div>
    <part :islook="look" :id="rescueId"></part>
  </div>
</template>

<script>
import part from "./part.vue";
import * as ola from "../api/olawebsock.js";
import * as api from "../api/car.js";
import AMap from "AMap";

export default {
  components: { part },
  props: ["warnid"],
  data() {
    return {
      look: 0,
      rescueId: "",
      emicreg: "",
      detail: {},
      warn: this.warnid,
      polyline: "",
      p1: "",
      pl: [],
      p2: "",

      value: "",
      isrescue: "1",
      textarea: "",
      policetit: false,
      replytit: "",
      policelist: [
        {
          id: 0,
          name: "动车吃饭",
        },
        {
          id: 1,
          name: "位置偏远，往城区靠",
        },
        {
          id: 2,
          name: "交警赶人，动车",
        },
        {
          id: 3,
          name: "停车要收费，附近动车停靠",
        },
        {
          id: 4,
          name: "下班，返回驻地",
        },
        {
          id: 5,
          name: "终点位置更改，动车前往",
        },
        {
          id: 6,
          name: "年检",
        },
        {
          id: 7,
          name: "保养",
        },
        {
          id: 8,
          name: "'拿资料",
        },
        {
          id: 9,
          name: "调刹车",
        },
        {
          id: 10,
          name: "处理违章",
        },
        {
          id: 11,
          name: "洗车",
        },
        {
          id: 12,
          name: "动车加油",
        },
        {
          id: 13,
          name: "私事请假动车",
        },
        {
          id: 14,
          name: "驻点地区无技师，返回驻地",
        },
        {
          id: 15,
          name: "外牌地区禁区限行，动车回靠",
        },
        {
          id: 16,
          name: "黄牌车停靠困难，动车找停靠点",
        },
        {
          id: 17,
          name: "gps定位不准,漂移误报",
        },
        {
          id: 19,
          name: "合伙人/外协",
        },
        {
          id: 18,
          name: "其他原因",
        },
      ],
    };
  },
  created() {
    if (this.warnid != "") {
      this.getdetail(this.warnid);
    }
  },
  mounted() {},
  methods: {
    policeChange(value) {
      this.replytit = value;
      //console.log(value);
      if (value == "其他原因") {
        this.policetit = true;
        this.replytit = this.textarea;
      } else {
        this.policetit = false;
        this.replytit = value;
      }
    },
    async create() {

      if(this.policetit == true)
      {
        this.replytit = this.textarea;
      }

      if(this.replytit == '')
      {
        this.$message.warning('请选择内容');
        return false;
      }
      let resub = await api.subenclosure({
        id: this.warnid,
        reply: this.replytit,
        status: this.isrescue,
      });

      if(resub.code == 1)
      {
        this.$message.success(resub.message);
        this.getdetail(this.warnid);
      }else{
        this.$message.warning(resub.message);
        this.getdetail(this.warnid);
      }
           
    },

    close() {
      this.$emit("guanenclosure");
    },
    enclosurepart(id) {
      this.rescueId = id;
      this.look++;
      if (this.look == 3) {
        this.look = 1;
      }
    },
    call(tel) {
      tel = tel.toString();
      let ue =  this.$store.state.emic;
      let mode = this.$store.state.emicmode;

      console.log("拨打电话", tel);
      ue.call.callout({
        customerNumber:tel.toString(),
        loginType:mode,
      });
    },

    async getdetail(id) {
      let res = await api.carWarning(id);

      this.detail = res.data;
      this.policelist = res.reason;

      if(res.data.reply != '')
      {
        let newArr = this.policelist.filter(function (ele) {
          return ele.name == res.data.reply;
        });

        //console.log(newArr);
        if (newArr.length > 0) {
          // console.log("数据存在数组中");
          this.value = res.data.reply;
        } else {
          // console.log("数据不存在数组中");
          this.value = "其他原因";
          this.policetit = true;
          this.textarea = res.data.reply;
          this.replytit = res.data.reply
        }
      }
     

      let res1 = await api.carWarningMap(id);
      // console.log('围栏信息',res.data);
      this.p1 = res1.p1;
      this.polyline = res1.polyline;
      this.pl = res1.pointlist;
      this.p2 = res1.p2;

      this.searchMap();
    },

    searchMap() {
      var suremap = new AMap.Map("suremap", {
        //center: [121.621989, 29.853658],
        resizeEnable: true,
        //zoom:15,
      });

      var infoWindow = new AMap.InfoWindow({ offset: new AMap.Pixel(0, 0) });

      var circle = new AMap.Circle({
        center: this.p1.location,
        radius: 1000, //半径
        borderWeight: 4,
        strokeColor: "#FF00FF",
        strokeOpacity: 1,
        strokeWeight: 2,
        strokeOpacity: 2,
        fillOpacity: 0.5,
        strokeStyle: "dashed",
        strokeDasharray: [10, 10],
        // 线样式还支持 'dashed'
        fillColor: "#87CEFA",
        zIndex: 50,
      });

      if (this.polyline != undefined && this.polyline != "") {
        var takeline = new AMap.Polyline({
          map: suremap,
          path: this.polyline,
          //borderWeight: 2, // 线条宽度，默认为 1
          strokeColor: "#2234ff", // 线条颜色
          lineJoin: "round", // 折线拐点连接处样式    //设置线覆盖物路径
          strokeWeight: 6, //线宽
          strokeOpacity: 0.8,
        });
        //console.log(this.detail.take_polyline);
        //map.add(takeline);
      }

      if (this.p1 != "") {
        var c1 = new AMap.Marker({
          position: new AMap.LngLat(
            this.p1["location"][0],
            this.p1["location"][1]
          ),
          map: suremap,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/stop1.png" ></br>' +
            "</div>",
        });

        c1.content =
          "<div class='maptime'> 时间：" +
          this.p1["time"] +
          "</div>" +
          "<div class='maptime'> 信息：" +
          this.p1["title"] +
          "</div>";
        c1.on("click", markerClick);
      }

      if (this.p2 != "") {
        var c2 = new AMap.Marker({
          position: new AMap.LngLat(
            this.p2["location"][0],
            this.p2["location"][1]
          ),
          map: suremap,
          content:
            '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/jingao.svg" ></br>' +
            "</div>",
        });

        c2.content =
          "<div class='maptime'> 时间：" +
          this.p2["time"] +
          "</div>" +
          "<div class='maptime'> 地址：" +
          "<div class='maptit'>" +
          this.p2["addr"] +
          "</div>" +
          "</div>";
        c2.on("click", markerClick);
      }

      if (this.pl.length > 0) {
        console.log(this.pl);
        for (var i = 0; i < this.pl.length; i++) {
          if (i == 0) {
            let dx = new AMap.Marker({
              position: new AMap.LngLat(this.pl[i]["lng"], this.pl[i]["lat"]),
              map: suremap,
              content:
                '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/start.png"> </br>' +
                "</div>",
            });

            dx.content =
              "<div class='maptime'> 时间：" +
              this.pl[i]["time"] +
              "</div>" +
              "<div class='maptime'> 地址：" +
              "<div class='maptit'>" +
              this.pl[i]["addr"] +
              "</div>" +
              "</div>";
            dx.on("click", markerClick);
          } else if (i == this.pl.length - 1) {
            let dx = new AMap.Marker({
              position: new AMap.LngLat(this.pl[i]["lng"], this.pl[i]["lat"]),
              map: suremap,
              content:
                '<div class="makerlabel"><img src="https://dispatch-test.nituowola.com/img/amap/stop.png"> </br>' +
                "</div>",
            });

            dx.content =
              "<div class='maptime'> 时间：" +
              this.pl[i]["time"] +
              "</div>" +
              "<div class='maptime'> 地址：" +
              "<div class='maptit'>" +
              this.pl[i]["addr"] +
              "</div>" +
              "</div>";
            dx.on("click", markerClick);
          } else {
            let dx = new AMap.Marker({
              position: new AMap.LngLat(this.pl[i]["lng"], this.pl[i]["lat"]),
              map: suremap,
              icon: "https://dispatch-test.nituowola.com/img/amap/jiantou.png",
              angle: this.pl[i]["drct"],
              offset: new AMap.Pixel(-7.5, -15),
            });

            dx.content =
              "<div class='maptime'> 时间：" +
              this.pl[i]["time"] +
              "</div>" +
              "<div class='maptime'> 速度：" +
              this.pl[i]["speed"] +
              "km/h <div class='mapjianju'></div>" +
              "距离：" +
              this.pl[i]["distacne"] +
              "公里</div>" +
              "<div class='maptime'> 地址：" +
              "<div class='maptit'>" +
              this.pl[i]["addr"] +
              "</div>" +
              "</div>";
            dx.on("click", markerClick);
          }
        }
      }

      function markerClick(e) {
        console.log("--------click", e);
        infoWindow.setContent(e.target.content);
        infoWindow.open(suremap, e.target.getPosition());
      }

      circle.setMap(suremap);
      suremap.setFitView([circle]);
      //suremap.setFitView([takeline]);
    },
  },
  computed: {
    getEmic() {
      return this.$store.state.emic;
    },
  },
  watch: {
    async warnid(newval) {},
    getEmic(newval) {
      this.emicreg = newval;
    },
  },
};
</script>

<style scoped>
.enclosurebox {
  width: 620px;
  position: absolute;
  top: 0px;
  right: 0;

  z-index: 5;
}
.cancelBox::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.cancelMain {
  width: 620px;
  height: 920px;
  background-color: #fff;
  float: right;
  position: relative;
  box-shadow: 0px -15px 20px rgba(179, 189, 195, 0.4);
  padding: 24px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.cancelMain::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.cha {
  width: 40px;
  height: 40px;
  position: absolute;
  left: -40px;
  top: 70px;
}
.cancelTit {
  font-size: 20px;
  font-weight: bold;
}

.information {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.informationLeft,
.informationRight {
  width: 274px;
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
}
.informationRight {
  margin-left: 24px;
}
.informationTit {
  padding-left: 10px;
  font-size: 16px;
  border-left: 2px solid #3c67f6;
  box-sizing: border-box;
  font-weight: 500;
  display: flex;
}
.carBox {
  margin-top: 10px;
  display: flex;
}
.carTxt {
  color: rgba(0, 0, 0, 0.45);
  margin-right: 8px;
  font-size: 14px;
}
.carTit {
  /* width: 100px; */
  font-size: 14px;
}
.callImg {
  margin-left: 10px;
}

.taskDetails {
  margin-top: 10px;
  background-color: #f8f8f8;
  padding: 10px;
  box-sizing: border-box;
}
.taskBox,
.taskBox2 {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 0;
}
.taskBox2 {
  align-items: center;
}
.taskBox2 /deep/ .el-select .el-input {
  width: 300px;
}
.taskBox2 /deep/ .el-textarea {
  width: 300px;
}
.taskjianju1 {
  justify-content: space-between;
  width: 540px !important;

}
.taskBoxTit {
  width: 180px;
  line-height: 20px;
}

.taskRenwu {
  width: 430px;
  font-size: 14px;
}

.tasktitmain {
  width: 200px;
}
.applynum {
  color: #2c68ff;
  font-size: 14px;
  cursor: pointer;
}
.tasktit {
  display: flex;
}

.map-box {
  display: flex;
  opacity: 1;
  border-radius: 0px;
  position: relative;
  margin-top: 20px;
}
#suremap {
  padding: 0px;
  margin: 0px;
  width: 620px;
  height: 300px;
}

.btn {
  width: 180px;
  height: 32px;
  opacity: 1;
  margin-top: 44px;
  margin-bottom: 30px;
}
.set {
  width: 80px;
  height: 32px;
  background: #2c68ff !important;
  opacity: 1;
  color: #fff !important;
  border: none;
  border-radius: 2px;
}
.cancel {
  width: 80px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #727e96;
  opacity: 1;
  margin-left: 20px;
  border-radius: 2px;
  vertical-align: top;

}
.dealyes{
  margin-left: 15px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  background: #FF8700;
  padding:1px 5px;
}
.tasktxt1{
  width:240px;
  display: flex;
}
.tasktxt2{
  width:120px;

}
.carTit{
  font-size: 12px;
  line-height: 20px;
}
</style>

<style>
.amap-info-content {
  padding: 10px;
}
.maptime {
  margin: 3px 0;
  display: flex;
}
.maptit {
  width: 250px;
}
.mapjianju {
  width: 20px;
}


</style>
