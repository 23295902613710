import http from "../util/http.js";
import http1 from "../util/http1.js";

// 车辆列表
//  /tech/list
export const carList = (data) => {
  let { page, perpage, keyword,uid,zhongduan,tai,status,ops} = data;
  return http
    .get("/tech/list", {
      params: {
        page,
        perpage,
        keyword,
        uid,
        zhongduan,
        tai,
        status,
        ops
      },
    })
    .then((res) => res.data);
};

//车辆详情
export const carDetail = (uid) => {
  return http
    .get("/tech/detail", {
      params: {
        uid,
      },
    })
    .then((res) => res.data);
};

//车辆更新
export const carUpdate = (
  uid,
  name,
  tel,
  carNumber,
  rescueType,
  type,
  carstyle,
  tel1,
  tel2,
  is_op,
  username,
) => {
  // let {addUid,addName,addTel,addType,addWU} = data;
  return http
    .post("/tech/do-edit", {
      uid: uid,
      name: name,
      tel: tel,
      carNumber: carNumber,
      rescueType: rescueType,
      type: type,
      carstyle: carstyle,
      tel1: tel1,
      tel2: tel2,
      is_op:is_op,
      username:username,
    })
    .then((res) => res.data);
};

// 司机添加
export const driverAdd = (data) => {
  let {
    username,
    name,
    rescueType,
    carNumber,
    type,
    carstyle,
    tel1,
    tel2,
    smsCode,
    is_op,
  } = data;
  return http
    .post("/tech/do-add", {
      username,
      name,
      rescueType,
      carNumber,
      type,
      carstyle,
      tel1,
      tel2,
      smsCode,
      is_op,
    })
    .then((res) => res.data);
};

//车辆自动接单 调度接单
export const carAuto = (uid, type) => {
  return http
    .post("/tech/auto-received", {
      uid: uid,
      type: type,
    })
    .then((res) => res.data);
};

export const carDisable = (uid) => {
  return http
    .post("/tech/do-disable", {
      uid: uid,
    })
    .then((res) => res.data);
};

export const carEnable = (uid, smscode) => {
  return http
    .post("/tech/do-enable", {
      uid: uid,
      smscode: smscode,
    })
    .then((res) => res.data);
};

export const carsendMessage = (mobile) => {
  return http
    .post("/site/send-message", {
      mobile: mobile,
      type: 4,
    })
    .then((res) => res.data);
};

export const getRescueList = () => {
  return http.get("/sys/get-rescue-list", {}).then((res) => res.data);
};

export const getStyleList = () => {
  return http.get("/sys/get-style-list", {}).then((res) => res.data);
};

export const getCarStyleList = () => {
  return http.get("/sys/get-car-style-list", {}).then((res) => res.data);
};

export const updateCarType = (ti, car_type) => {
  return http
    .post("/sys/update-car-type", {
      car_type: car_type,
      ti: ti,
    })
    .then((res) => res.data);
};


export const carWarning = (id) => {
  return http
    .post("/sys/car-warning", {
      id:id
    })
    .then((res) => res.data);
};

export const carWarningMap = (id) => {
  return http1
    .post("/sys/car-warning-map", {
      id:id
    })
    .then((res) => res.data);
};

export const subenclosure = (data) => {
 let { id, reply, status } = data;
 return http
.post("/sys/warning-reply", {
 id,
 reply,
 status,
   })
 .then((res) => res.data);
  };

  export const getsp= () => {
    return http
   .post("/service-provider/get-s-p", {})
    .then((res) => res.data);
     };

     export const getspmore= (cid) => {
      return http
     .post("/service-provider/get-s-p-c", {cid:cid})
      .then((res) => res.data);
       };



//车辆聚合功能
export const setBlack = (uid, type) => {
  return http
    .post("/tech/set-black", {
      uid: uid,
      type: type,
    })
    .then((res) => res.data);
};